import styled from '@emotion/styled';
import { css } from '@emotion/core';

const contentStyles = ({ theme: { color } }) => css`
  max-width: 500px;
  margin: 0 auto;
  text-align: left;

  li p {
    margin: 0;
  }

  a {
    color: ${color.black};
    text-decoration: underline;
    &:hover,
    &:active {
      color: ${color.black};
    }
  }
`;

const headerStyles = css`
  text-align: center;
  margin: 50px 0;
`;

const figureStyles = css`
  margin: 0 0 30px;
`;

const externalIconStyles = css`
  width: 24px;
  position: relative;
  top: 4px;
`;

/**
 * Component style declarations
 */
export const Content = styled('section')(contentStyles);
export const Header = styled('header')(headerStyles);
export const Figure = styled('figure')(figureStyles);
export const ExternalIcon = styled('img')(externalIconStyles);
