/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Page, SEO, Container, Heading, EmbedVideo } from '../../components';
import { MainNavigation } from '../../compositions';
import { Content, Header, Figure, ExternalIcon } from './ImpactSolution.style';
import icon from '../../images/icon-exit.svg';

const ImpactSolution = ({ data }) => {
  const [article] = data.allContentfulSubmissions.edges;
  const content = article.node;
  const { title, body, region, submissionLink, videoID } = content;

  return (
    <Page>
      <SEO title={`${title} | Virtual Refugee Conference`} />
      <MainNavigation />
      <Container>
        <Content>
          <Header>
            <Heading element="h1" size="h2">
              {title}{' '}
              <a href={submissionLink} target="_blank" rel="noopener noreferrer">
                <ExternalIcon src={icon} alt="External link icon" />
              </a>
            </Heading>
            <span>{region}</span>
          </Header>
          {videoID && (
            <Figure>
              <EmbedVideo
                videoID={videoID}
                aspectRatio="16-9"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Figure>
          )}
          {documentToReactComponents(body.json)}
        </Content>
      </Container>
    </Page>
  );
};

export const pageQuery = graphql`
  query impactSolutionsQuery($slug: String!) {
    allContentfulSubmissions(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          slug
          title
          region
          submissionLink
          videoID
          body {
            json
          }
        }
      }
    }
  }
`;

ImpactSolution.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImpactSolution;
